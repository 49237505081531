.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.videochat-container {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 9999999;
}

.videochat-in-call {
  display: flex;
  margin-top: 50px;
  height: inherit;
  width: inherit;
  background-color: #000011;
}

.videochat-incoming {
  background-color: rgba(0, 0, 0, 0.8);
  width: 32em;
  height: 8em;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
}

.videochat-hidden {
  display: none;
}

.videochat-stream-loader {
  margin: auto;
  left: 0;
  right: 0;
  padding: 1em;
}

.videochat-buttons-container {
  position: absolute;
  bottom: -35px;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  justify-content: center;
}

.videochat-stream-container {
  flex: 1;
  position: relative;
}

.videochat-stream {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.videochat-buttons-container>button {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 5px;
  border-color: transparent;
}

.videochat-mute-unmute {
  background: #0000ff url("./assets/images/mic.svg") no-repeat center;
}

.videochat-mute-unmute.muted {
  background: #0000ff url("./assets/images/mic_off.svg") no-repeat center;
}

.videochat-call {
  background: #75b73b url("./assets/images/call.svg") no-repeat center;
}

.videochat-stop-call {
  background: #ff0000 url("./assets/images/call_end.svg") no-repeat center;
}

.videochat-switch-camera {
  background: #ffa500 url("./assets/images/switch_video.svg") no-repeat center;
}

.incoming-videochat-buttons {
  bottom: 5px;
}