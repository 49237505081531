.react-calendar {
    /* width: 350px; */
    max-width: 100%;
    border-bottom: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    background: #fff !important;
    background-color: #fff !important;
    /* position: fixed; */
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    height: 35px;
    /*height: 44px; */
    /* margin-bottom: 1em; */
    background: #fff !important;
    border-bottom: 1px solid #a0a096;
}

.react-calendar__navigation button {
    background: none;
    font-size: 18px;
    margin-top: -0.1em;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    /* background-color: #e6e6e6; */
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

.react-calendar__month-view {
    height: auto;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(1em) calc(0.6666666666666666em);
}
.react-calendar__month-view__days__day--weekend .react-calendar_week-name {    
    color: rgb(7, 175, 72);
  }

.react-calendar__month-view__days__day--weekend .react-calendar__month-view-number {    
  color: rgb(7, 175, 72);
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.5em 1em;
    background: #fff;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    /* background-color: #e6e6e6; */
}

.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
}

.react-calendar--selectRange .react-calendar__tile--hover {
}

.react-calendar__tile--active .react-calendar__month-view-number {
    background: rgb(201, 201, 201);
    background: linear-gradient(180deg, rgba(201, 201, 201, 1) 0%, rgba(255, 255, 255, 1) 65%);
    background: linear-gradient(180deg, rgba(201, 201, 201, 1) 0%, rgba(255, 255, 255, 1) 65%);
    border-radius: 50%;
    height: 1.9em;
    margin-top: 0.5em;
    width: 1.9em;
    padding: 0.3em;
    font-size: 16px !important;
    display: inline-block;
    border: 1px solid #dcd8d8;
    position: relative;
}

.react-calendar__tile--now .react-calendar__month-view-number {
    border-radius: 50%;
    display: inline-block;
    height: 1.6em;
    width: 1.6em;
    padding: 0.3em;
    font-size: 16px;
    margin-top: 0.5em;
    background-color:rgba(156, 148, 148,0.5);
}

.react-calendar__month-view-number {
    border-radius: 50%;
    display: inline-block;
    height: 1.6em;
    width: 1.6em;
    padding: 0.3em;
    font-size: 16px;
    margin-top: 0.5em;
    position: relative;
}

.react-calendar_week-name {
    font-size: 16px;
    font-weight: 700;
}

.next-month-btn {
    font-size: 32px !important;
}

.react-calendar__tile--allData .react-calendar__month-view-number {
    border-radius: 50%;
    display: inline-block;
    height: 1.6em;
    width: 1.9em;
    padding: 0.3em;
    font-size: 16px;
    margin-top: 0.5em;
    position: relative;
}

.dot-calendar {
    width: 6px;
    height: 6px;
    position: absolute;
    z-index: 9999999;
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: -3px;
    margin: 0 auto;
}

.react-calendar__tile--allData .react-calendar__month-view-number .dot-calendar {
    background: #667bfb;
}
